<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <div style="display: flex">
      <el-form :model="form" :rules="rules" ref="form" label-width="200px" style="width:600px">
        <el-form-item label="反馈账号">
            <el-input v-model="form.email" disabled></el-input>
            <el-button type="info" size="mini" style="position: absolute;margin-top: 6px;margin-left: 2px;" @click="AccountInfo">账号详情</el-button>
        </el-form-item>
        <el-form-item label="反馈类型">
            <el-input v-model="form.type" disabled></el-input>
        </el-form-item>
        <el-form-item label="产品型号">
            <el-input v-model="form.product_id" disabled></el-input>
        </el-form-item>
        <el-form-item label="反馈时间">
            <el-input v-model="form.create_time" disabled></el-input>
        </el-form-item>
        <el-form-item v-if="question_answer!==''" label="已回复内容">
          <el-input :rows="5" type="textarea" disabled v-model="question_answer"></el-input>
        </el-form-item>
        <el-form-item label="状态">
            <el-select v-model="form.status" placeholder="">
                <el-option label="未处理" :value="1"></el-option>
                <el-option label="处理中" :value="2"></el-option>
                <el-option label="已处理" :value="3"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item prop="backcontent" label="回复内容">
          <el-input :rows="5" type="textarea" v-model="form.backcontent"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="Validate(submit)">提交</el-button>
        </el-form-item>
      </el-form>
      <div style="margin-left:200px;flex:1">
        <h4 style="margin: 17px 0">反馈内容</h4>
        <el-input :rows="10" disabled type="textarea" v-model="form.content"></el-input>
        <div class="image" v-show="imgShow">
          <img @click="checkImg(img)" style="height:100px" v-for="(img,key) in formimges" :key="key" :src="img">
          <el-image-viewer @close="closeImgViewer" :url-list="srcList" v-if="showImageViewer"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script type="text/ecmascript-6">
import util from '../../../util/util'
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      form: {
        content: '',
        create_time: '',
        email: '',
        image: '',
        product_id: '',
        status: '',
        type: '',
        backcontent: '',
        uid: ''
      },
      rules: {
        backcontent: [{ required: true, message: '请输入回复内容', trigger: 'blur' }],
      },
      infocode: '',
      feedtypeIdName: {},
      modelIdName: {},
      content: '',
      formimges: [],
      question_answer: '',
      imgShow: false,
      imgslist: [],
      srcList: [],
      showImageViewer: false
    }
  },
  computed: {},
  watch: {},
  methods: {
    AccountInfo() {
      this.$router.push('/iot/userinfo/'+ this.form.uid + '&' + localStorage.getItem('region_change') + '&' + 'feedback' + '&' + this.infocode)
    },
    checkImg (item) {
      this.srcList = []
      this.srcList.push(item)
      this.imgslist.forEach(element => {
        if (element !== item) {
          this.srcList.push(element)
        }
      })
      this.showImageViewer = true
    },
    closeImgViewer(){
      this.showImageViewer=false
    },
    GetfeedType(){
      this.api.FeedTypeList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.GetModelList()
          res.data.data.data.forEach(item => {
            this.feedtypeIdName[item.id] = item.name
          })
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetModelList () {
      this.api.ProductModelList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          res.data.data.data.forEach(item => {
            this.modelIdName[item.id]=item.name
          })
          this.GetInfo()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetInfo () {
      this.api.FeedBackListDetail({
        code: this.infocode
      }).then(res => {
        if (res.data.code == 200) {
          this.form = res.data.data.feedback
          this.formimges = res.data.data.images
          this.form.type = this.feedtypeIdName[res.data.data.feedback.type]
          this.form.product_id = this.modelIdName[res.data.data.feedback.product_id]
          this.form.create_time = util.dateFormat(res.data.data.feedback.create_time)
          if (res.data.data.feedback.question_answer) {
            res.data.data.feedback.question_answer.forEach(item => {
              this.question_answer = this.question_answer+(this.question_answer!==''?'\n\n':'') + '|' + item.content
            })
          }
          if (res.data.data.images) {
            this.imgShow = true
          }
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetImg () {
      this.imgslist = []
      this.formimges.forEach(item => {
        this.api.S3down({key:item}).then(res => {
          if (res.data.code == 200) {
            this.imgslist.push({url: res.data.data.get_url})
            return
          }
          this.$message.error('下载S3图片失败:' + res.data.msg)
        })
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    submit () {
        this.api.FeedBackListStatus({
            content: this.form.backcontent,
            id: this.form.id,
            status: this.form.status
        }).then(res => {
          if (res.data.code == 200) {
            this.$confirm('修改成功，是否返回反馈列表?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.goback()
            })
            return
          }
          this.$message.error('修改失败' + res.data.msg)
        })
    },
    goback () {
      this.$router.push('/iot/feedbacklist')
    }
  },
  filters: {},
  mounted () {
    this.infocode = this.$route.params.code
  },
  created () {
    this.GetfeedType()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.image {height: 100px; text-align: right;}
</style>
